.inputs {
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-cons {
  display: flex;
  flex-direction: column;
}

.input-cons * {
  margin: 0;
  margin-block: 2px;
}

.full-input {
  border: none;
  text-align: right;
  color: var(--Neutral-Very-dark-cyan);
  font-size: var(--font-size-form-inputs);
  background-color: var(--Neutral-Very-light-grayish-cyan);
  padding: 3px 1rem;
  border-radius: 5px;
}

.inputs .headings {
  color: var(--Neutral-Grayish-cyan);
}

.percentages-con-grid {
  display: grid;
  width: 100%;
  grid-template-columns: var(--inputs-grid-items-template-columns);
  gap: 1rem;
}

.percentage--input {
  border: none;
  font-size: var(--font-size-form-inputs);
  background-color: var(--Neutral-Very-dark-cyan);
  color: var(--Neutral-White);
  border-radius: 5px;
  padding-block: 5px;
  transition: background-color 0.3s ease-in, color 0.1s ease-in;
}

.percentage--input:hover {
  background-color: hsl(173, 63%, 78%);
  color: var(--Neutral-Very-dark-cyan);
  transition: background-color 0.3s ease-in, color 0.1s ease-in;
}

.selected {
  background-color: var(--Primary-Strong-cyan);
  color: var(--Neutral-Very-dark-cyan);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  /* border: 1px solid red; */
}

.custom-input {
  text-align: right;
  background-color: var(--Neutral-Very-light-grayish-cyan);
  padding-right: 1rem;
  color: var(--Neutral-Very-dark-cyan);
}

.custom-input::placeholder {
  color: var(--Neutral-Very-dark-cyan);
  opacity: 0.8;
  font-size: medium;
}

.custom-input:hover {
  border: 2px solid hsl(172, 31%, 52%);
  outline: none;
  background-color: var(--Neutral-Very-light-grayish-cyan);
}

.custom-input:focus {
  border: 2px solid hsl(172, 31%, 52%);
  outline: none;
}

.nop-p-con {
  display: flex;
  justify-content: space-between;
}

.hidden {
  visibility: hidden;
}

.alert {
  visibility: visible !important;
  color: hsl(10, 56%, 59%) !important;
}

.alert-input {
  color: hsl(10, 56%, 59%) !important;
  border: 1px solid hsl(10, 56%, 59%) !important;
  transition: all 0.4s ease-in;
}

.alert-input:focus,
.alert-input:hover,
.alert-input:active {
  color: hsl(10, 56%, 59%) !important;
  border: 1px solid hsl(10, 56%, 59%) !important;
}
